(function(window, document, undefined) {
'use strict';
/// here is where the dragon lives
$(function () {

    $(document).ready(function () {
        $('.fancybox').fancybox({
            helpers : {
                title : {
                    type : 'inside'
                }
            }
        });
    });

});

})(window, document);
